export class UserApp{
    constructor(
        public id_user: string,
        public nombre: string,
        public correo: string,
        public contrasena: string,
        public telefono: string,
        public status: string,
        public createAt: string,
    ){}
}
