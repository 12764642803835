export class Stock{
    constructor(
        public id_stock: string,
        public vivero: string, 
        public responsable: string,
        public empresa_procedencia: string,
        public nombre: string,
        public apellidos: string,
        public telefono: string,
        public arbol_tipo1: string, 
        public arbol_tipo2: string,
        public arbol_tipo3: string,
        public arbol_tipo4: string,
        public arbol_cantidad1: string, 
        public arbol_cantidad2: string,
        public arbol_cantidad3: string,
        public arbol_cantidad4: string,
        public status: string
    ){}
}

