import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Stock } from '../models/stock';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { reject } from 'q';

moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class StockService {
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';


  constructor(private http: HttpClient) { }

  getStock(): Observable<Stock[]> {
    return this.http.get<any>(`${environment.url_api}getStock`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.stock)).map(item => {
        return new Stock(
          item.id_stock,
          item.vivero,
          item.responsable,
          item.empresa_procedencia,
          item.nombre,
          item.apellidos,
          item.telefono,
          item.arbol_tipo1,
          item.arbol_tipo2,
          item.arbol_tipo3,
          item.arbol_tipo4,
          item.arbol_cantidad1,
          item.arbol_cantidad2,
          item.arbol_cantidad3,
          item.arbol_cantidad4,
          item.status
        );
      });
    })
    );
  }

  getStockbyId(id: string): Observable<Stock[]> {
    return this.http.get<any>(`${environment.url_api}getStockbyId/${id}`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.stock)).map(item => {
        return new Stock(
          item.id_stock,
          item.vivero,
          item.responsable,
          item.empresa_procedencia,
          item.nombre,
          item.apellidos,
          item.telefono,
          item.arbol_tipo1,
          item.arbol_tipo2,
          item.arbol_tipo3,
          item.arbol_tipo4,
          item.arbol_cantidad1,
          item.arbol_cantidad2,
          item.arbol_cantidad3,
          item.arbol_cantidad4,
          item.status
        );
      });
    })
    );
  }

  getStockbyVivero(vivero: string): Observable<Stock[]> {
    return this.http.get<any>(`${environment.url_api}getStockxvivero/${vivero}`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.stock)).map(item => {
        return new Stock(
          item.id_stock,
          item.vivero,
          item.responsable,
          item.empresa_procedencia,
          item.nombre,
          item.apellidos,
          item.telefono,
          item.arbol_tipo1,
          item.arbol_tipo2,
          item.arbol_tipo3,
          item.arbol_tipo4,
          item.arbol_cantidad1,
          item.arbol_cantidad2,
          item.arbol_cantidad3,
          item.arbol_cantidad4,
          item.status
        );
      });
    })
    );
  }

  addStock(add: Stock): Observable<Stock> {
    const data = {
      vivero: add.vivero,
      responsable: add.responsable,
      empresa_procedencia: add.empresa_procedencia,
      nombre: add.nombre,
      apellidos: add.apellidos,
      telefono: add.telefono,
      arbol_tipo1: add.arbol_tipo1,
      arbol_tipo2: add.arbol_tipo2,
      arbol_tipo3: add.arbol_tipo3,
      arbol_tipo4: add.arbol_tipo4,
      arbol_cantidad1: add.arbol_cantidad1,
      arbol_cantidad2: add.arbol_cantidad2,
      arbol_cantidad3: add.arbol_cantidad3,
      arbol_cantidad4: add.arbol_cantidad4,
    };

    return this.http.post<any>(`${environment.url_api}addStock`, data).pipe(
      map((result) => {
        if (result.OK) {
          return result.addStock;
        } else {
          throw new Error("Error");
        }
      }),
      catchError((error) => {
        console.error("Error al agregar", error);
        return throwError(error);
      })
    );
  }

  updateStock(edit: Stock) {
    let data = {
      arbol_cantidad1: edit.arbol_cantidad1,
      arbol_cantidad2: edit.arbol_cantidad2,
      arbol_cantidad3: edit.arbol_cantidad3,
      arbol_cantidad4: edit.arbol_cantidad4,
    };

    return this.http
      .put<any>(`${environment.url_api}updateStock/${edit.id_stock}`, data)
      .pipe(
        map((result) => {
          if (result.OK) {
            return result;
          } else {
            return reject();
          }
        })
      );
  }




  DelStock(id: string) {
    return this.http.delete<any>(`${environment.url_api}DelStock/${id}`).pipe(
      map((result) => {
        return result;
      })
    );
  }


  public exportExcel(jsonData: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

}